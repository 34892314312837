.services-container {
  display: flex;
  flex-direction: column;
  width: 100vw;
  padding: 20px;
  align-items: center;
  position: relative;
  min-height: 85vh;
  padding: 60px 40px;
}

.content-wrapper {
  position: relative;
  width: 80%;
  margin: 0 auto;
  z-index: 1;
}

.title {
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
}

.title h1 {
  font-family: Helvetica, sans-serif;
  font-weight: 200;
  letter-spacing: 10px;
  font-size: 40px;
  color: white;
  width: 100%;
  display: flex;
  justify-content: center;
}

.services-list {
  display: flex;
  flex-direction: column;
}

.service-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-family: Helvetica, sans-serif;
  font-weight: 200;
  letter-spacing: 5px;
  line-height: 70px;
  font-size: 26px;
  color: white;
  background-color: transparent;
  padding: 10px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.service-item.active {
  background-color: rgba(255, 255, 255, 0.7);
  color: #456769;
}
.service-title {
  margin-left: 10px;
}

.plus-sign {
  margin-left: 10px;
  transition: transform 0.3s ease;
}

.plus-sign.active {
  transform: rotate(45deg);
}

.expanded-content {
  padding: 30px 70px 70px 70px;
  background-color: rgba(255, 255, 255, 0.7);
  color: #456769;
}

hr {
  margin: 0;
  border: 1px solid #ccc;
}

@media (max-width: 768px) {
  .content-wrapper {
    width: 90%;
  }

  .title h1 {
    font-size: 30px;
    letter-spacing: 5px;
  }

  .service-item {
    font-size: 28px;
    letter-spacing: 10px;
  }
  .service-item {
    letter-spacing: 4px;
  }
  .expanded-content {
    padding: 20px;
  }
  .services-container {
    margin-bottom: 0px;
    padding-bottom: 0px;
    min-height: 70vh;
  }
}
