/* TermsPage.css */
.terms-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.terms-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #afafaf;
  opacity: 0.7;
  z-index: 1;
}

.terms-card {
  background-color: white;
  padding: 40px;
  border: 22px solid #54a7ac;
  max-width: 600px;
  width: 90%;
  border-radius: 10px;
  text-align: left;
  z-index: 2;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 200;
}

.terms-title {
  font-size: 18px;
  font-weight: bold;
  margin-top: 0;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: #54a7ac;
  margin: 20px 0;
}

.term-voucher {
  list-style-type: none;
  padding-left: 0;
  margin: 0;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  z-index: 3;
  color: #54a7ac;
}
