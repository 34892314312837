/* GiftVoucher.css */
.gift-voucher {
  font-size: 15px;
  letter-spacing: 1px;
  line-height: 28px;
  color: #456769;
  margin-bottom: 20px;
  padding: 10px;
}

.terms-gift {
  margin-top: 16px;
  font-weight: 200;
}

.terms-link {
  color: #3450c3;
  text-decoration: none;
}

.terms-link:hover {
  cursor: pointer;
}
