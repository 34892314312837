html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

h2 {
  font-family: "Helvetica", "Helvetica Neue", Arial, sans-serif;
  font-weight: 200;
  font-size: 20px;
  color: #25595c;
}

.landing-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.landing-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
}

.quote-container {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: rgb(226, 226, 226);
  text-align: center;
  padding: 20px;
  border-radius: 10px;
}

.spaImg {
  max-width: 80%;
  height: auto;
}

.arrow {
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 40px;
  color: #25595c;
  z-index: 10;
}

.landing-container:hover {
  cursor: pointer;
}

@media (max-width: 768px) {
  .arrow {
    bottom: 50px; /* Position 50px from the bottom */
  }
}
