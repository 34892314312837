.face-container {
  width: 100%; /* Full width for the container */
}

.table-header {
  display: grid; /* Use CSS Grid for headers */
  grid-template-columns: 50% 25% 25%; /* Set Treatment to 50%, Min and Price to 25% each */
  font-weight: bold; /* Make headers bold */
  margin-bottom: 10px; /* Space below the header */
}

.header-item {
  text-align: left; /* Align text to the left */
}

.face-menu {
  list-style-type: none; /* Remove default list styling */
  padding: 0; /* Remove padding */
  margin: 0; /* Remove margin */
  font-size: 16px;
}

.menu-item {
  display: grid; /* Use CSS Grid for items */
  grid-template-columns: 50% 25% 25%; /* Set Treatment to 50%, Min and Price to 25% each */
  margin-bottom: 2px; /* Adjust margin as needed */
}

.facial,
.minutes,
.price {
  text-align: left; /* Align text to the left */
  padding: 5px; /* Add padding for better spacing */
}

/* Change background color with opacity for even rows */
.menu-item:nth-child(even) {
  background-color: rgba(249, 249, 249, 0.3); /* Light gray with 70% opacity */
}

.terms {
  margin-top: 40px;
  font-size: 13px;
  font-style: italic;
}
