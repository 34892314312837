.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

html,
body {
  margin: 0;
  font-family: "Helvetica", "Helvetica Neue", Arial, sans-serif;
}

h2 {
  color: #25595c;
}

.app-container {
  background-color: rgb(244, 244, 244);
  display: flex;
  flex-direction: column;
  width: 100vw;
  min-height: 100vh;
  overflow: hidden;
  background-color: #5da5b1;
}
p {
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 28px;
  color: #456769;
  margin-bottom: 20px;
  padding: 10px;
  font-weight: 200;
}

.main-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* Footer.css */
footer {
  width: 20vw; /* Full width of the viewport */
  height: 100px; /* Height of the footer */
  position: fixed; /* Fix it to the bottom left of the viewport */
  bottom: 0; /* Position it at the bottom */
  left: 0; /* Align it to the left */
  z-index: 1000; /* Make sure it appears above other content */
  display: flex;
  justify-content: flex-start; /* Align content to the left */
  align-items: center; /* Center content vertically */
  padding: 10px; /* Add padding */
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
