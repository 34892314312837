/* About.css */
.about-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding: 40px 20px;
  min-height: 70vh;
  width: 100vw;
}

.about-content {
  display: flex;
  flex-direction: row;
  max-width: 1200px;
  width: 100%;
  gap: 20px;
  align-items: center;
  padding: 30px;
}

.about-image {
  flex: 0 0 40%; /* 40% width for the image */
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.8;
}

.about-image img {
  height: 200px;
  max-width: 96%;
  border-radius: 40px;
  object-fit: cover;
}

.about-text {
  flex: 1; /* Remaining space for text */
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 70px 0;
  max-width: 50%;
}

.about-heading {
  font-size: 23px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 300; /* Light font weight */
  color: #25595c;
  margin-bottom: 15px;
  letter-spacing: 0.1em; /* 100 for letter spacing in px is too much, using 0.1em instead */
  line-height: 42px; /* Line height for the top div */
}

.about-description {
  font-size: 18px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 300; /* Light font weight */
  color: #6a6a6a;
  letter-spacing: 0.1em; /* Adjust letter spacing */
  line-height: 26px; /* Line height for the bottom div */
}

/* Vertical divider between the image and text */
.divider {
  width: 1px;
  background-color: #2d2d2d;
  height: 80%;
  margin: 0 20px;
}

/* Responsive styling for mobile devices */
@media (max-width: 768px) {
  .about-content {
    flex-direction: column;
    text-align: center;
  }

  .about-image {
    width: 100%; /* Full width for the image */
    margin-bottom: 20px;
  }

  .about-text {
    max-width: 100%; /* Full width for text on mobile */
    padding: 20px 0;
  }

  .about-heading {
    font-size: 24px;
    line-height: 36px; /* Adjust line height on mobile */
  }

  .about-description {
    font-size: 16px;
    line-height: 24px; /* Adjust line height on mobile */
  }

  .divider {
    display: none; /* Hide the divider on mobile */
  }
}
