.events-container {
  width: 100%;
  letter-spacing: 1px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.events-image-container {
  display: flex;
  justify-content: flex-start;
  align-items: center; /* Center the image vertically */
  width: 100%;
}

.events-text {
  max-width: 70%;
  margin-left: 20px;
}

.events-img {
  height: 160px;
  opacity: 0.85;
  border-radius: 2%;
  display: block; /* Use block instead of flex for the image itself */
  text-align: left;
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  /* Adjust the max-width as needed for your design */
  .events-img {
    height: auto; /* Set height to auto on mobile */
    width: 100%; /* Set width to 60% on mobile */
  }
  .events-container {
    width: 100%;
    letter-spacing: 1px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .events-text {
    max-width: 100%;
    margin-left: 0px;
  }
}
